import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardApp } from './core/guards/auth.guard';
import { WallComponent } from './shared/components/wall/wall.component';
import { AttachmentDragDropComponent } from './shared/components/dynamicUI/controls/attachment-DragDrop/attachment-DragDrop.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { AuthGuardKeycloak } from './core/guards/auth.keycloakguard';
import { APP_BASE_HREF } from '@angular/common';
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component';
import { UnauthComponent } from './shared/components/unauth/unauth.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/app-wall', pathMatch: 'full'
  },
  {
    path: "attach",
    component: AttachmentDragDropComponent,
    canActivate: [AuthGuardKeycloak, AuthGuardApp],
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [AuthGuardApp]
  },
  {
    path: "maintenance",
    component: MaintenanceComponent,
    canActivate: [AuthGuardApp]
  },
  { path: 'unauth', component: UnauthComponent },
  { path: 'vendor', loadChildren: () => import('./modules/vendor/vendor.module').then(m => m.VendorModule) },
  { path: 'timesheet', loadChildren: () => import('./modules/timesheet/timesheet.module').then(m => m.TimesheetModule)},
  {
    path: "app-wall",
    component: WallComponent,
    canActivate: [AuthGuardKeycloak, AuthGuardApp],
    // data: {
    //   breadcrumb: "Home",
    // },

    children: [
      {
        path: "app-generichome",
        loadChildren: () => import('./form/generictemplate/generictemplate.module').then(m => m.GenericTemplateModule),
      },
      {
        path: "app-services",
        loadChildren:
        () => import('./form/viewallservices/viewallservices.module').then(m => m.ViewAllServicesModule),
      }
      ,
      { path: '**', redirectTo: 'app-wall', },

    ],
  },
  { path: '**', redirectTo: 'app-wall',}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuardKeycloak,AuthGuardApp, { provide: APP_BASE_HREF, useValue: '/' }]
})
export class AppRoutingModule { }
