import { ChangeDetectorRef, Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/core/services/shared.service';
import { environment } from 'src/environments/environment.prod';
import { HeaderComponent } from '../header/header.component';
import { Subscription } from 'rxjs';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss']
})
export class WallComponent implements OnInit {
  userDetailsData: any;
  roles: any[] = [];
  entities: any[] = [];
  entityRoles: any[] = [];
  roleAndFeatures: any[] = [];
  links: any[] = [];
  @ViewChild(HeaderComponent, { static: false }) headerComp: HeaderComponent;
  private refreshSubscription: Subscription;
  unAuthFlag: boolean=true;

  constructor(private router: Router, private sharedService: SharedService, private cd: ChangeDetectorRef, private route: ActivatedRoute, private bnIdle: BnNgIdleService) {

    this.bnIdle.startWatching(environment.idlesessiontime).subscribe({ next : (res) => {
      if(res) {
          alert("Your session has expired. Please relogin");
          localStorage.clear();
          this.headerComp.logout();
          // location.reload();
      }
    }, error:(err) => {
      // this.telemetryLogger.fnError(err,IMPRESSIONEVENTS.VIEW,Appconstants.wallcomponent,window.location.href);
    }})
   }

  ngOnInit(): void {
    console.log("On login user details api is called then section json routing will happen based on tabs selection on header.")
    this.userdetails();
    // this.refreshSubscription = this.sharedService.refresh$.subscribe(() => {
    //   // Refresh the job cards here
    //   // console.log("hey jobs");
    //   this.userdetails();
    // });
  }

  userdetails() {
    this.sharedService.getUserDetails().subscribe(data => {
      console.log(data);
      if (data) {
        this.userDetailsData = data;
        this.roles = this.userDetailsData["roles"];
        this.entities = this.userDetailsData["entities"];
        this.entityRoles = this.userDetailsData["entityRoles"];
        this.sharedService.changedRoleDetails(this.entityRoles);
        this.roleAndFeatures = this.userDetailsData["roleAndFeatures"];
        this.links = this.userDetailsData["links"];
        // localStorage.setItem("firstName", this.userDetailsData['firstName']);
        // localStorage.setItem("lastName", this.userDetailsData['lastName']);
        // localStorage.setItem("email", this.userDetailsData['email']);
        // localStorage.setItem("vendorCode", this.userDetailsData['id']);
        localStorage.setItem("vendorName",this.userDetailsData["vendorName"]);
      }
    }, (error) => {
      // console.log("error in user details api");
      let info = {
        msg: "Technical issue occured, please try again later",
        isSuccess: false
      }
      this.sharedService.openSnackBar(info,'success-snack');
    })
  }

  homeEvent(event) {
    if (event) {
      this.headerComp.tabChanged(0);
      this.headerComp.selectedTabIndex = 0;
    }
  }

  ngOnDestroy() {
    // this.refreshSubscription.unsubscribe();
  }

}
