import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import * as jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { EnvironmentLoaderService } from './environment-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // path = 'api/v1/keycloak/careersite';
  path = 'api/v1/keycloak/PartnerHub';
  entityId = localStorage.getItem("companyHiringTypeId");
  selectedRole = localStorage.getItem("selectedRole");
  appName = localStorage.getItem("appName");

  
  baseUrlPartnerHubServices: string='';
  timesheetBaseUrl: string='';

  private _roleDetails = new BehaviorSubject({});
  _currentDetails = this._roleDetails.asObservable();

  private refreshSubject = new BehaviorSubject<void>(null);
  refresh$ = this.refreshSubject.asObservable();

  private accessSubject = new BehaviorSubject<boolean>(null);
  access$ = this.accessSubject.asObservable();

  constructor(private http: HttpClient, private keycloakService: KeycloakService, private envLoaderSerive: EnvironmentLoaderService, private snackBar: MatSnackBar) {

    this.envLoaderSerive.getEnvData().then((envjson) => {
      environment.partnerHubUrlInTap = envjson['InTapPartnerHubUrl'];
      environment.partnerHubServices = envjson['PartnerHubServices'];
      environment.ecmsPartnerHubGateWayUrl = envjson['ECMSPartnerHubGateWayUrl'];
      environment.KeyClockServiceUrl = envjson['KeyClockServiceUrl'];
      environment.timeSheetServiceUrl=envjson['TimeSheetServiceUrl'];
      this.timesheetBaseUrl=environment.timeSheetServiceUrl;
    });
    console.log(environment.timeSheetServiceUrl, "env", environment);
    // this.baseUrlPartnerHubServices= environment?.partnerHubServices;
  }

  changedRoleDetails(roleDetails) {
    this._roleDetails.next(roleDetails);
  }

  triggerRefresh() {
    this.refreshSubject.next();
  }

  accessCheck(accessValue) {
    this.accessSubject.next(accessValue);
  }

  getUserDetails() {
    this.baseUrlPartnerHubServices=environment.partnerHubServices;
    // console.log(this.baseUrlPartnerHubServices,"user", environment.partnerHubServices);
    // this.baseUrlPartnerHubServices=environment?.partnerHubServices;
    // return this.http.get("/assets/json/loginuserdetails.json");
    // return this.http.get<any>(this.baseUrlLocalPartnerHubServices + "/api/userDetails");
    // return this.http.get("/assets/json/vendorjobsECMS.json");
    return this.http.get<any>(this.baseUrlPartnerHubServices + "api/userDetails");
  }

  //change or update password //keycloak
  resetPassword(resetObj) {
    let accessToken = localStorage.getItem("access_token");
    let jwttoken1 = jwt_decode(accessToken);
    let kcUserId = jwttoken1.sub;
    // return this.http.post<any>("https://infysso-poc.infosysapps.com/adminapi/api/v1/keycloak/PartnerHub/" + this.kcUserId + "/password/isfupdate", resetObj)
    let baseurl=environment.KeyClockServiceUrl;
    return this.http.post<any>(baseurl  + kcUserId + "/password/isfupdate", resetObj)
  }

  //keycloak
  logoutSession() {
    // localStorage.clear();
    // let baseurl=environment.keyClock; 
    // const requestOptions: Object = {
    //   responseType: 'text'
    // }
    // //path need to be changed here
    // return this.http.post<any>(baseurl + this.path + '/' + userId + '/logout', '',requestOptions)
    let idToken = this.keycloakService.getKeycloakInstance().idToken;
    let baseurl=environment.keyClock;
    window.location.replace(baseurl+"/realms/" + "PartnerHub" + "/protocol/openid-connect/logout?post_logout_redirect_uri=" + window.location.origin + "&id_token_hint=" + idToken);
    // return this.http.post<any>("https://intaptest.infosysapps.com/careers/search/keycloak/api/v1/keycloak/careersite/<user_uuid>/logout")
  }

  getDisclaimerConsent(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    this.appName=localStorage.getItem("appName");
    // return this.http.get("/assets/json/fetchConsent.json"); 
    // return this.http.get<any>(this.baseUrlLocalPartnerHubServices + "/getdpoconsent?appName=" + this.appName + "&entityId=" + this.entityId,httpOptions);
    // fetchdpoconsent?reqId=12345&appName=INTAP&intEntityId=1
    return this.http.get<any>(this.baseUrlPartnerHubServices + "fetchdpoconsent?appName=" + this.appName + "&entityId=" + this.entityId, httpOptions);
  }

  SaveConsent(url, consentObj) {
    if (url) {
      return this.http.post<any>(url, consentObj)
    }
    return this.http.post<any>(this.baseUrlPartnerHubServices + "saveconsent", consentObj)
  }

  dpoCheck(reqId, appName) {
    console.log(this.baseUrlPartnerHubServices, "base", environment);
    this.entityId=localStorage.getItem("companyHiringTypeId");
    return this.http.get<any>(this.baseUrlPartnerHubServices + "dpoCheck?reqId=" + reqId + "&appName=" + appName + "&entityId=" + this.entityId);
    // return this.http.get<any>(this.baseUrlLocalPartnerHubServices + "/dpoCheck?reqId=" + reqId + "&appName=" + appName + "&entityId=" + this.entityId);
    // /dpoCheck?reqId=123345&appName=INTAP&intEntityId=1
  }

  getJobApply(appName) {
    // return this.http.get<any>("/assets/json/jobApplyData.json")
    this.entityId=localStorage.getItem("companyHiringTypeId");
    return this.http.get<any>(this.baseUrlPartnerHubServices + "getJobApplyJson?entityId=" + this.entityId + "&appName=" + appName);
    // return this.http.get<any>(this.baseUrlLocalPartnerHubServices + "/getJobApplyJson?entityId=" + this.entityId + "&appName=" + appName);

    // /getJobApplyJson?entityId=1&roleCode=ROLE_ECMS_ALL
  }

  //keycloak
  forgotPassword(data): Observable<any> {
    // let baseurl=environment.keyCloakUserUrl; 
    // let careersiteUrl=environment.careersiteUrl; 
    let baseurl=environment.KeyClockServiceUrl;
    return this.http.post<any>(baseurl + data.emailX + "/password/resetRL", '')
    // return this.http.post<any>(`https://infysso-poc.infosysapps.com/adminapi/api/v1/keycloak/PartnerHub/${data.emailX}/password/resetRL`,'')
    return this.http.post<any>(baseurl + data.emailX + "/password/reset", '')
    // return this.http.post<any>(baseurl + this.path + '/' + data.emailX + '/password/reset', '',requestOptions)
  }

  openSnackBar(message: any, panelClass: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: 2500,
    });
  }
  openSnackBarNew(message: any, panelClass: string,duration:any) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: duration,
    });
  }

  restrictSpclChar(e){
    var key = document.all ? e.keyCode : e.which;
    return ((key > 64 && key < 91) || (key > 96 && key < 123) || key == 8 || key == 32 || key >= 48 && key <= 57 || key == 45 || key == 46 || key == 44);
  }

  rstrctPasteSpclChar(e){
    if(e && e.clipboardData && e.clipboardData.getData('text')){
        if(!(e.clipboardData.getData('text').match('^[a-zA-Z0-9 .,-]*$'))){
          return false;
       }
    }  
  }

  dpoCheckForSubcon(roleSelected) {
    this.entityId=localStorage.getItem("companyHiringTypeId");
    https://partnerhubtst.infosysapps.com/myinfy/gatewaypartnerhub/partnerhub/partnerhubapi/subConDpoCheck?appName=InTap&entityId=1
    return this.http.get<any>(this.baseUrlPartnerHubServices + "subConDpoCheck?roleName=" + roleSelected + "&entityId=" + this.entityId);
    return this.http.get<any>("/assets/json/privacyAccepted.json");
  }

  getTimesheetAuth() {
    console.log(this.timesheetBaseUrl, "env timesheet");
    return this.http.get<any>(this.timesheetBaseUrl + "v1/timetrnemptimesheets/TimesheetLoginAuthorization");
    // https://itgatewaytst.infosys.com/gatewaypartnerhub/timesheet/v1/timetrnemptimesheets/TimesheetLoginAuthorization
  }

}
