<!-- <p>wall works!</p> -->
<div class="designView active">
    <div class="DSA_mainwrapper">
        <app-header *ngIf="userDetailsData" [roles]="roles" [entities]="entities" [entityRoles]="entityRoles" [roleAndFeatures]="roleAndFeatures" (homeEvent)="homeEvent($event)" [links]="links" [userDetailsData]="userDetailsData"></app-header>
        <div class="DSA_baseLayout_center padT" style="margin-bottom: 20px;">
            <router-outlet></router-outlet>
            <!-- <app-workflow></app-workflow> -->
        </div> 
                <!-- <div class="ds_mainContent" >
                    <mat-drawer-container class="mobilemenuCtr" >
                            <mat-drawer-content>
                                    
                            </mat-drawer-content>
                        </mat-drawer-container>
                   
                </div> -->

            <!-- </div> -->
             <!-- <app-footer></app-footer>  -->
             <!-- <app-new-footer></app-new-footer> -->
    </div>
</div>


