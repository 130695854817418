<header class="Infy_Header">
    <div id="headerComp-header-DIV" class="containerPos" style="vertical-align: center">
        <!-- <a mat-button [matMenuTriggerFor]="menu" class="cus" href="javascript:void(0)" >
                <img src="assets\images\vendor_images\Component 159 – 1@2x.png" class="menu-style  x-24" style="color:white">
            </a>            
                <mat-menu #menu="matMenu"> -->
        <!-- <button style="color:#8626C3;opacity: 100%;font-family:'Myriad Pro' Semibold; font-size: 18px;"mat-menu-item>Job Opportunities</button>
                    <button style="color:#737373; font-size: 14px; opacity: 100%;" mat-menu-item>Candidates</button>
                    <button style="color:#737373; font-size: 14px; opacity: 100%;" mat-menu-item>Invoice</button> -->
        <!-- <button style="color:#737373; font-size: 14px; opacity: 100%;" mat-menu-item>Credit Notes</button>
                    <a style="color:#8626C3; font-size: 14px; opacity: 100%;"mat-menu-item class="anchorIn"href="http://www.infosys.com/about/Pages/index.aspx" target="_blank">About Us</a>
                    <a style="color:#8626C3; font-size: 14px; opacity: 100%;" mat-menu-item href="http://www.infosys.com/contact/Pages/index.aspx" target="_blank">Contact Us</a>
                </mat-menu> -->

        <a class="customMenuAnchorSize" (click)="home()">
            <img class="customLogoSize responsive-logo" alt="logo" src="assets/images/vendor_images/Group 17520@2x.png"/>
        </a>

       

        <div class="lineStyle"></div>

        <span class="text-style">Partner Hub</span>

        <div class="selectStyle" *ngIf="!unAuthFlag">
            <mat-select class="selectPadFont" [(value)]="companyHiringTypeId"
                (selectionChange)="getHeaderEntities(companyHiringTypeId)">
                <mat-option *ngFor="let entity of entities" [value]="entity.id">
                    {{ entity.organization }}
                </mat-option>
            </mat-select>
        </div>
        
        <ul id="headerComp-header-div2" class="float-right">
            <!-- <li>
                <a class="customNotifStyleAnchor" href="javascript:void(0)"><em
                        class="notifStyle icon x-24 white notification-icon"></em>
                </a>
            </li> -->
            <li>
                <span>
                    <a #menuContacts="MatMenuTrigger" *ngIf="condition" [matMenuTriggerFor]="menu2"
                        class="custom-button" href="javascript:void(0)"><img src="assets\images\homeAvatar.png" />
                    </a>
                    <a *ngIf="!condition" [matMenuTriggerFor]="menu2" class="custom-button menuTriggerAnchorStyle"
                        href="javascript:void(0)">
                        <span>{{ userDetailsData?.vendorName | initials }}</span>
                    </a>
                    <mat-menu #menu2="matMenu">
                        <button mat-menu-item>
                            <!-- <span class="userNameClass">Hello, {{ userDetailsData?.vendorName }}</span> -->
                            <span class="userNameClass">Hello, {{userDetailsData?.vendorName}} !</span>
                        </button>
                        <div *ngIf="!unAuthFlag">
                            <mat-form-field (click)="$event.stopPropagation()" style="margin-left: 10px; margin-right: 10px"
                            appearance="outline" floatLabel="always">
                            <mat-label>Switch role</mat-label>
                            <mat-select [(value)]="selectedRole" (selectionChange)="closeMenu()">
                                <mat-option *ngFor="let role of roles" [value]="role.role">
                                    {{ role.displayName
                                    }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        </div>
                        
                        <button mat-menu-item *ngIf="(!unAuthFlag && !infyUser ) || !infyUser">
                            <a class="menuButtonDecor" href="javascript:void(0)"
                                (click)="userProfileDetails()"><span>Your Profile</span>
                            </a>
                        </button>
                        <button *ngIf="(!unAuthFlag && !infyUser ) || !infyUser" class="menuButtonDecor" mat-menu-item (click)="changePassword()">
                            Change Password
                        </button>
                        <button class="menuButtonDecor" mat-menu-item (click)="logout()">
                            Logout
                        </button>
                    </mat-menu>
                </span>
            </li>
        </ul>
    </div>
</header>
<!-- <mat-tab-group style ="color:white; margin-top: 7px;" #tabgroup [(selectedIndex)]="selectedTabIndex">
        <div>
            <mat-tab label='Job Opportunities'>
            </mat-tab>
            <mat-tab label='Candidates'>
            </mat-tab>
            <mat-tab label='Invoices'>
            </mat-tab>
            <mat-tab label='Credits'>
            </mat-tab>
            <mat-tab label='Reports'>
            </mat-tab>
        </div>
    </mat-tab-group> -->
<div *ngIf="!hideTabs" class="d-flex justify-content-between tabDivStyle">
        <div style="min-width: 230px;" class="col-7 ">
        <div [ngClass]="selectedRole=='ROLE_REC_ALL' ? 'lateralMaxWidth':'' " class="d-flex">
            <div [ngClass]="selectedRole=='ROLE_REC_ALL' ? 'widthLateralTabs': 'col-12' "
            class="DSA_wb_customTab DSA_navTabWhite DSA_wb_Secondary marL32 marT4 nopadL">
            <mat-tab-group disableRipple #tabGroup (selectedTabChange)="tabChanged($event.index)" [(selectedIndex)]="selectedTabIndex">
                <mat-tab *ngFor="let tab of tablists" #tab>
                    <!-- <ng-template mat-tab-label >
                        {{ tab.feature }}
                    </ng-template> -->
                    <ng-template mat-tab-label>
                        <span class="tab-label ">{{ tab.feature }}</span>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="selectedRole=='ROLE_REC_ALL' && companyHiringTypeId=='1'" class="marT13 widthPayBtn">
            <button class="paymentBtn " (click)="openDialog()">Payment</button>
        </div>
        </div>
    </div>
    <!-- <div class="d-flex"  [ngClass] = "selectedRole=='ROLE_REC_ALL' ? 'col-md-3': 'col-md-7' ">
        <div class="DSA_wb_customTab DSA_navTabWhite DSA_wb_Secondary marL32 marT4 nopadL">
            <mat-tab-group disableRipple #tabGroup (selectedTabChange)="tabChanged($event.index)" [(selectedIndex)]="selectedTabIndex">
                <mat-tab *ngFor="let tab of tablists" #tab>
                    <ng-template mat-tab-label>
                        {{ tab.feature }}
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="selectedRole=='ROLE_REC_ALL' && companyHiringTypeId=='1'" class="marT13 col-md-2">
            <button class="paymentBtn" (click)="openDialog()">Payment</button>
        </div>
    </div> -->
    <!-- Mobile view starts  -->
    <button  mat-icon-button class="burgerbtn" [matMenuTriggerFor]="menu" >
        <mat-icon class="burger">{{isMenuOpen ? "close" : "menu"}}</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button  mat-menu-item class="menuBtn" [matMenuTriggerFor]="menuHP">Helplinks</button>
        <mat-menu #menuHP="matMenu">
            
                <div *ngFor="let link of helpLinks"><a class="text-underline"  mat-menu-item class="menuOptn" matTooltip="{{link?.description}}" matTooltipClass="DSA_tooltip" matTooltipPosition="above" (click)="downloadFile(link?.url)">{{link?.name}} </a>
                </div>
            
            </mat-menu>
        <a *ngFor="let link of links" mat-menu-item href="{{link.value}}" target="_blank" class="anchorDesign marT8">{{link.label}}</a>
    </mat-menu>
     <!-- Mobile view ends  -->

    <div class="menuDesk">
        <button class="menuBtn" mat-button [matMenuTriggerFor]="menuDT">Helplinks<mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #menuDT="matMenu">
            <div *ngIf="helpLinks">
                <div *ngFor="let link of helpLinks"><a class="text-underline"  mat-menu-item class="menuOptn" matTooltip="{{link?.description}}" matTooltipClass="DSA_tooltip" matTooltipPosition="above" (click)="downloadFile(link?.url)">{{link?.name}} </a>
                </div>
            </div>
        </mat-menu> 
        <span *ngFor="let link of links" class="marT8">
            <a href="{{link.value}}" target="_blank" class="anchorDesign">{{link.label}}</a>
        </span>  
    </div>
</div>