
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
// import { TelemetryService } from '../../../../node_modules/@infosys-it/angular-telemetry-sdk';
// import { KeyCloak } from '../../shared/models/operations_persona';

//import { AuthenticationService } from '@app/_services';
// to get KeyCloak Token
// import { ResumeParserService } from '../services/resume-parser.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    token: any;
    constructor() {
        this.token = localStorage.getItem("access_token");
        // this.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJiMTg3NDYyNy1lMzlkLTQ5NzYtYjM3MC1hMGVlN2UzODNhMjQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC82M2NlN2Q1OS0yZjNlLTQyY2QtYThjYy1iZTc2NGNmZjVlYjYvIiwiaWF0IjoxNjk0MTUyNTYxLCJuYmYiOjE2OTQxNTI1NjEsImV4cCI6MTY5NDE1NzExOSwiYWNyIjoiMSIsImFpbyI6IkFZUUFlLzhVQUFBQUNwS0RtM1dudlhHNG1tZlhtcEpEa0o2QXlXMTc1cVJxRjN6REZmSng4ckRQbWRkUmVzaTIwdFo5MUs3VHN5c2lhRTJPZ21MMnl0YWJlUHhXOFVzaDY1Y0V4SStRRjFjc3RMMjQ1bEQ4Y3dIUDRjYkpWL1ViaU9lY1JUVmljK3hrK2Z1LytOR1hqc3VIMTRveGlMK0NaVkpyUkp6ZFRRODRYTDkwblRtWTVjTT0iLCJhbXIiOlsicHdkIiwicnNhIiwibWZhIl0sImFwcGlkIjoiYjE4NzQ2MjctZTM5ZC00OTc2LWIzNzAtYTBlZTdlMzgzYTI0IiwiYXBwaWRhY3IiOiIxIiwiZGV2aWNlaWQiOiJkNWE3MTIwZi0xNTc4LTRiNDEtYTQ1Ny1mZTE0MTJiNDliZjciLCJmYW1pbHlfbmFtZSI6Ikd1cnJhbSIsImdpdmVuX25hbWUiOiJTYWkgUHJhc2FubsKgR291ZCIsImlwYWRkciI6IjEzNi4yMjYuMjU0LjEyNyIsIm5hbWUiOiJTYWkgUHJhc2FubiBHb3VkIEd1cnJhbSIsIm9pZCI6IjRmNTE5NWIyLTcwMGYtNGMzZC04YTc3LTg3ZjVkOGMxNWVmNiIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0yNjY3NDk5NDAtMTYzNzk2NDQ0NC05Mjk3MDEwMDAtMzY1Mzk4NSIsInJoIjoiMC5BUVlBV1gzT1l6NHZ6VUtvekw1MlRQOWV0aWRHaDdHZDQzWkpzM0NnN240NE9pUUdBUDAuIiwic2NwIjoiVXNlci5SZWFkIiwic3ViIjoiNkJkdURKeEhrZURMMk5ETkZtc2JiYmROWGFkcXhUclJmRkRCa2N1eEFqMCIsInRpZCI6IjYzY2U3ZDU5LTJmM2UtNDJjZC1hOGNjLWJlNzY0Y2ZmNWViNiIsInVuaXF1ZV9uYW1lIjoic2FpcHJhc2FubmdvdWQuZ0BhZC5pbmZvc3lzLmNvbSIsInVwbiI6InNhaXByYXNhbm5nb3VkLmdAYWQuaW5mb3N5cy5jb20iLCJ1dGkiOiJoY2JVNEVyblJFeUU3ZldqMG9ZVUFBIiwidmVyIjoiMS4wIiwiRW1wTm8iOiIxMDM3NDUxIn0.hl7pygEQAWnFT9bTVsaxkEcSR4kuxlZHoc6iJEdwObd6cKAb9mQWflwEIGXt6brFB3JjbasmPmr3a407TacpnKFP0fr8nRiMNgnuzliafad_AoCBJMSIPDYaeqBwJcMFK5D1O878m0bWORXapEl_6g1z18cOK3rpBrj1kxaDSW0hUkhVyEySdqcRXqQA3snmhtHbzDq_OsuFxDiGPFFWtFMe2XMEaOydGHe8gyYOvF0NWoSboL8tXMEsIx8PakYNHJY86mU9BqJbDhr2-laKomF7VdndQFdSZWC3blbzx0xXrsVRJ1zCYlzwApPYZ7-ZXjHCOnyZH8f53cxTL6j49A";
        // this.service.provider = 'SUNBIRD';
        // this.service.setProviderImplementation('SUNBIRD');
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<any> {

        
        this.token = localStorage.getItem("access_token");
        const token = this.token;
        if (!!token && !req.url.includes("maintenance-connectinfy.json") && !req.url.includes("botConfigConnectInfy.json") && 
        !req.url.includes(
            "/forgot-password"
            )) {
            // console.log('woow interceptor', token);

            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body && evt.body.success) {
                        // let telemetryEventData = {
                        //     //required details
                        //     "type": "INTERRUPT",
                        //     "pageid": "Interceptor",
                        //     "id": evt.body.success,
                        //     "data": evt.body
                        // }
                        // this.service.interrupt(telemetryEventData)
                    }
                }
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    try {
                        // this.toasterService.error(err.error.message, err.error.title, { positionClass: 'toast-bottom-center' });
                        // let telemetryError = { // Required
                        //     "err": err.error.code, // Required. Error code
                        //     "errtype": "APIERROR", // Required. Error type classification - "SYSTEM", "MOBILEAPP", "CONTENT"
                        //     "stacktrace": err.error, // Required. Detailed error data/stack trace
                        // };
                        // this.service.error(telemetryError)
                    } catch (e) {
                        // let telemetryEventData = {
                        //     //required details
                        //     "type": "INTERRUPT",
                        //     "pageid": "Interceptor",
                        //     "errMessage": "An error occured"
                        // }
                        // let telemetryError = { // Required
                        //     "err": err.error.code, // Required. Error code
                        //     "errtype": "APIERROR", // Required. Error type classification - "SYSTEM", "MOBILEAPP", "CONTENT"
                        //     "stacktrace": err.error, // Required. Detailed error data/stack trace
                        // };
                        // this.service.error(telemetryError)
                        // this.service.interrupt(telemetryEventData)
                    }
                    //log error 
                }
                return throwError(err);
            }));

    }
}
